import brushCoat from "../images/brush-coat.jpg";
import whiteMembrane from "../images/white-membrane.png";
import whiteMembrane2 from "../images/white-membrane-2.jpeg";
import crystallinePowder from "../images/crystalline-powder.png";
import crystallinePowder2 from "../images/Crystalline-Waterproofing-Coating.avif";
import brushCoat2 from "../images/brush-coat-2.png";
import blackSeal from "../images/blackseal.jpeg";

const productsData = [
  {
    slug: "brush-coat",
    name: "Brush Coat 2K",
    category: `Brush coat is a two-component high-performance elastomeric cementitious flexible
    sealing coat engineered to combat ground and backwater.
    Formulated with a modified acrylic polymer emulsion, this
    cutting-edge solution provides exceptional waterproofing
    and remarkable UV resistance.
    What sets this product apart is its superior adhesion to
    cementitious surfaces, coupled with its flexible nature that
    can withstand expansions and contractions without
    compromising integrity.
    This flexibility not only ensures durability but also allows for breathing, making it an ideal
    choice for a wide range of applications.
    Say goodbye to water infiltration and UV damage with our advanced elastomeric
    cementitious sealing coat. Trust it to provide lasting protection and peace of mind against
    the harshest environmental conditions.`,
    basePrice: 1600, // Price for the default quantity
    quantities: [
      { label: "7kg", value: 1600 },
      { label: "20kg", value: 2500 },
    ],
    images: [brushCoat, brushCoat2],
    features: [
      "Waterproofing Excellence: BrushCoat offers superior waterproofing performance, making it ideal for use on concrete, masonry, and other surfaces exposed to moisture.",
      "Durable Protection: This coating forms a tough and durable layer that withstands environmental elements, UV exposure, and wear and tear, extending the lifespan of treated surfaces and it is non-toxic and can be used for portable water tanks",
      "Crack-bridging ability",
      "UV-resistant properties",
    ],
  },
  {
    slug: "white-membrane",
    name: "White Membrane",
    category: `Ober Damp Proof White Membrane 1k is a single-component 100% pure high-performance abrasion acrylic
    elastomeric waterproof exterior coating for
    external walls, terraces, sloping roofs, MS/ACC
    sheets, and other surfaces. It is designed as a
    membrane coating to improve elongation
    properties and bridge existing and future cracks
    over a wide temperature range. It is a self-
    curing component that, when applied, provides
    an impenetrable, robust, hard, and abrasion
    resistant covering. It has great strength and
    inflammability, preventing peeling, fungus, and
    algae growth. It offers a waterproof and weather-resistant covering that is unaffected by
    seawater, soap water, mild acids, and alkalis.It serves as a multi-functional and very
    eﬃcient heat transfer barrier that is completely waterproof.`,
    basePrice: 8900, // Price for the default quantity
    quantities: [{ label: "20L", value: 8900 }],
    images: [whiteMembrane, whiteMembrane2],
    features: [
      "Self-curing, no external curing required; excellent breaking strength, rip and abrasion resistant",
      "Seamless membrane with excellent breathability. Highly resistant to fungal and microorganism development.",
      "Performs effectively in the service temperature range of -20°C to 80°C.",
      "At room temperature, it hardens into a strong, flexible, and wear-resistant surface.",
      "Reliable crack bridging - Excellent elongation properties and recovery",
      "Excellent resistant to mild acids, Alkalies, Oils /solvents and other chemicals.",
      "Resistant to UV light and weathering exposure, reflects the sunlight and reduces the room temperature around 8°C with three coats of application.",
      "Can be used on bitumen coated surfaces also.",
      "As white in colour it can be intend with universal stainers for desired colour shades.",
    ],
  },
  {
    slug: "black-membrane",
    name: "Black Membrane",
    category: `Black Seal Membrane 1K is a High Performance, Elastomeric Acrylic
    Bituminous Blended Waterproof Coating for wide
    range of applications, surfaces includes concrete,
    steel, chemical treatment floors etc. The water based
    high solid, cold applied elastomeric liquid membrane
    coating in unique formulation of single component for
    user friendly ready to use compound based on acrylic
    and bituminous blended emulsion recommended for
    Terrace, Sloped Roofs, MS / ACC sheets, External
    side of retaining walls, Bathrooms, Toilets etc.`,
    basePrice: 8500, // Price for the default quantity
    quantities: [{ label: "20L", value: 8500 }],
    images: [whiteMembrane, blackSeal],
    features: [
      "High strength, Elastomeric seamless liquid membrane formation for water vapour barrier.",
      "Excellent resistant to fungus and micro organism growth.",
      "Water base ready to use material ideal to apply in moist surfaces and dry applications.",
      "High performance elastomeric membrane.",
      "High resistant to UV light and weathering exposure.",
      "It is resistant to water, dilute acid, dilute alkali and salt solutions.",
      "High flexibility, high breathability of the coating gives puncture resistance at wider temperature range.",
      "Excellent adhesion properties with the applied various kinds of surface like Cement, Asbestos, Steel, Wood, Tile etc.",
      "Cures to a tough and abrasion resistant coating.",
      "Excellent adhesion on old bituminous surfaces, can be used on existing bitumen tarfelt for repairing.",
      "It is an alternative and the best treatment than tarfelt / APP membrane when we treat with GEO fabric, being there is no over lap joints.",
    ],
  },
  {
    slug: "crystalline-powder",
    name: "Crystalline Powder",
    category: `Ober Crystalline Powder is a cement-based, brush-applied waterproofing solution for concrete. When mixed with water and applied, it penetrates the concrete, forming millions of needle-like crystals that block water pathways. These crystals develop deeper over time, depending on concrete thickness, environmental conditions, and moisture levels, achieving full waterproofing within 7 days to a month. Ober Crystalline Powder reacts with moisture to form insoluble crystalline complexes that seal capillaries and microcracks, even under high hydrostatic pressure. This active waterproofing technology remains effective throughout the life of the concrete, providing permanent water resistance`,
    basePrice: 1675, // Price for the default quantity
    quantities: [
      { label: "20kg", value: 2400 },
      { label: "50kg", value: 4800 },
    ],
    images: [crystallinePowder, crystallinePowder2],
    features: [
      "Stops water flow through concrete and protects the reinforcing steel from corrosion.",
      "Waterproofs small cracks and seals shrinkage cracks up to 0.4 mm wide.",
      "Its waterproofing performance improves with time and is permanently operational.",
      "Does not suffer from surface wear or abrasion.",
      "Can be applied to both the positive and negative sides of concrete.",
      "Treated concrete can sustain hydrostatic pressure up to 150m head.Simple to apply, just combine water on-site.",
      "Protects concrete against polluted water and corrosion.",
      "Suitable for damp substrates. And allows concrete to breathe, but not a vapour barrier.",
      "Deep crystallisation provides waterproofing for landscaping slabs, concrete rafts, and PCC.",
    ],
  },
  // Add more products as needed
];

export default productsData;
