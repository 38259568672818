// src/utils/GoogleAnalytics.js
export const initGoogleAnalytics = (trackingId) => {
  if (!window.gtag) {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag("js", new Date());
      gtag("config", trackingId);
    };
  }
};

export const trackPageView = (url) => {
  if (window.gtag) {
    window.gtag("config", "G-E4MMTVCC9L", { page_path: url });
  }
};
